import React, { useEffect, useState } from 'react';
import Separator from '~/components/Separator';

import { Row, Col } from 'react-bootstrap';
import { useEntradaNFE } from '~/pages/EntradaNFE/EntradaNFEContext';
import {
  InputMoney,
  InputNumber,
  InputPeso,
  InputText,
} from '~/components/NovosInputs';

export const FormExportador: React.FC = () => {
  const { formNfe, canUpdate } = useEntradaNFE();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { register, control, watch } = formNfe;

  const watchCodPessoa = watch('cod_pessoa');
  const watchCodPerfil = watch('cod_perfil');

  useEffect(() => {
    if (
      watchCodPessoa?.flg_produtor_rural &&
      watchCodPerfil?.tipo_operacao === 1
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [watchCodPessoa, watchCodPerfil]);

  return (
    <>
      <Separator labelText="Exportador" sidePadding="0 0" />

      <Row>
        <Col md={2} sm={12}>
          <InputMoney
            label="Seguro"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_seguro"
            register={register}
            disabled={!canUpdate || disabled}
            isError={false}
            showIcon
          />
        </Col>
        <Col md={2} sm={12}>
          <InputNumber
            control={control}
            label="Qtd. Emb."
            maxLength={5}
            max={150}
            min={0}
            placeholder="0"
            name="qtd_embalagem"
            register={register}
            disabled={!canUpdate || disabled}
            isError={false}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputPeso
            label="Peso Liq."
            placeholder="0,00"
            decimals={2}
            name="qtd_peso_liq"
            register={register}
            control={control}
            disabled={!canUpdate || disabled}
            isError={false}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputPeso
            label="Peso Bruto"
            placeholder="0,00"
            decimals={2}
            name="qtd_peso_bruto"
            register={register}
            control={control}
            disabled={!canUpdate || disabled}
            isError={false}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputText
            label="Marca Veic."
            maxLength={50}
            placeholder=""
            toLowerCase={false}
            name="des_marca_veiculo"
            register={register}
            isError={false}
            disabled={!canUpdate || disabled}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputText
            label="Placa Veic."
            maxLength={50}
            placeholder=""
            name="des_placa_veiculo"
            register={register}
            disabled={!canUpdate || disabled}
            isError={false}
            toLowerCase={false}
          />
        </Col>
        <Col md={4} sm={12}>
          <InputText
            label="Natureza"
            maxLength={50}
            placeholder=""
            name="des_natureza"
            register={register}
            disabled={!canUpdate || disabled}
            isError={false}
          />
        </Col>
      </Row>
    </>
  );
};
