import { cnpj, cpf } from 'cpf-cnpj-validator';
import * as yup from 'yup';
import { transformAsCurrency } from '~/utils/functions';

export const schemaFormNfe = yup
  .object({
    num_nf: yup
      .number()
      .required()
      .min(0, 'O número da NF não pode ser negativo.')
      .max(9999999999, 'O número da NF deve ter no máximo 10 dígitos.'),
    dta_emissao: yup
      .string()
      .required('Data de emissão é obrigatória.')
      .test(
        'format',
        'Data de emissão deve ser no formato DDMMYYYY',
        (value: any) => {
          return value && value.replace(/\D/g, '').length === 8;
        },
      )
      .test(
        'date-comparison',
        'Data de emissão não deve ser maior que a data de saída',
        function (value: any) {
          const { dta_entrada, flg_confirmar } = this.parent;
          if (!flg_confirmar && dta_entrada) {
            return new Date(value) <= new Date(dta_entrada);
          }
          return true;
        },
      ),
    dta_entrada: yup.string().when('flg_confirmar', {
      is: (flg_confirmar: boolean) => {
        return !flg_confirmar;
      },
      then: yup
        .string()
        .required('Data de saída é obrigatória.')
        .test((value) => {
          value = value?.replace(/\D/g, '');
          return value?.length === 8;
        }),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    val_total_nf: yup
      .string()
      .required()
      .test((val) => {
        const valueToFloat = transformAsCurrency(val || 0);
        return valueToFloat > 0;
      }),
    cod_perfil: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.number(),
        // tipo_emissao: yup.number(),
      })
      .required(),
    cod_serie: yup.mixed().when('cod_perfil', {
      is: (value: any) => {
        if (value !== null) {
          return value?.tipo_emissao === 0;
        }
        return true;
      },
      then: yup.string().required(),
      otherwise: yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
        .required(),
    }),
    des_especie: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
    cod_pessoa: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.number(),
      })
      .required(),
    // validacao condicional
    num_nf_produtor: yup.string().when('cod_pessoa', {
      is: (pessoa: { flg_produtor_rural: boolean }) => {
        return pessoa?.flg_produtor_rural;
      },
      then: yup
        .string()
        .required()
        .max(
          10,
          'O número do NF do produtor deve ter no máximo 10 caracteres.',
        ),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    num_serie_produtor: yup.string().when('cod_pessoa', {
      is: (pessoa: { flg_produtor_rural: boolean }) => {
        return pessoa?.flg_produtor_rural;
      },
      then: yup.string().required(),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    dta_emissao_produtor: yup.string().when('cod_pessoa', {
      is: (pessoa: { flg_produtor_rural: boolean }) => {
        return pessoa?.flg_produtor_rural;
      },
      then: yup
        .string()
        .required()
        .test((value) => {
          value = value?.replace(/\D/g, '');
          return value?.length === 8;
        }),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    num_cpf_cnpj_produtor: yup.string().when('cod_pessoa', {
      is: (pessoa: { flg_produtor_rural: boolean }) => {
        return pessoa?.flg_produtor_rural || false;
      },
      then: yup
        .string()
        .test((num_cpf_cnpj_produtor: any) => {
          // Remove os caracteres especiais e verifica possui 11 ou 14 digitos
          const num: string = num_cpf_cnpj_produtor?.replace(/\D/g, '') || '';
          if (num?.length === 14) {
            return cnpj.isValid(num);
          }
          return cpf.isValid(num);
        })
        .required(),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    num_rg_ie_produtor: yup.string().when('cod_pessoa', {
      is: (pessoa: { flg_produtor_rural: boolean }) => {
        return pessoa?.flg_produtor_rural || false;
      },
      then: yup.string().min(9).max(14).required(),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    des_uf_produtor: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string(),
      })
      .when('cod_pessoa', {
        is: (pessoa: { flg_produtor_rural: boolean }) => {
          return pessoa?.flg_produtor_rural || false;
        },
        then: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required(),
          })
          .required(),
        otherwise: yup
          .object()
          .shape({
            label: yup.string(),
            value: yup.string(),
          })
          .notRequired()
          .nullable(true),
      }),
    num_chave_acesso: yup.string().when(['des_especie', 'cod_perfil'], {
      is: (des_especie: any, cod_perfil: any) => {
        return (
          des_especie?.des_especie === 'NFE' && cod_perfil?.tipo_emissao === 0
        );
      },
      then: yup
        .string()
        .length(44)
        .required('num_chave_acesso é obrigatório quando des_especie é "NFE"'),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    des_especie_produtor: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string(),
      })
      .when('cod_pessoa', {
        is: (pessoa: { flg_produtor_rural: boolean }) => {
          return pessoa?.flg_produtor_rural || false;
        },
        then: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required(),
          })
          .required(),
        otherwise: yup
          .object()
          .shape({
            label: yup.string(),
            value: yup.string(),
          })
          .notRequired()
          .nullable(true),
      }),
    des_obs_fiscal: yup.string().when('cod_pessoa', {
      is: (pessoa: { flg_produtor_rural: boolean }) => {
        return pessoa?.flg_produtor_rural || false;
      },
      then: yup.string().min(1).max(500).required(),
      otherwise: yup.string().notRequired().nullable(true),
    }),
  })
  .required();

export const schemaFormItem = yup
  .object({
    qtd_embalagem: yup.number().required().moreThan(0),
    qtd_entrada: yup.number().required().moreThan(0),
    cod_tributacao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    cod_produto: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
    cfop: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
    val_tabela: yup
      .string()
      .required()
      .test((val) => {
        const valueToFloat = transformAsCurrency(val || 0);
        return valueToFloat > 0;
      }),
  })
  .required();

export const schemaFormParcela = yup
  .object({
    finalizadora: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    // num_condicao: yup.number().required(),
    // condicao: yup.object().shape({
    //   label: yup.string().required(),
    //   value: yup.number().required(),
    // }),
    dta_vencimento: yup.string().required(),
    val_parcela: yup
      .string()
      .required()
      .test((val) => {
        const valueToFloat = transformAsCurrency(val || 0);
        return valueToFloat > 0;
      }),
  })
  .required();
