import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  CreatableSelect,
  InputDate,
  InputMoney,
  InputNumber2,
  InputSelect,
  InputText,
  InputUf,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { useEntradaNFE } from '~/pages/EntradaNFE/EntradaNFEContext';
import { Uf } from '~/pages/EntradaNFE/protocols';
import api from '~/services/api';
import { errorHandler } from '~/utils/ErrorHandler';

type Select = {
  value: string | number;
  label: string;
};

export const FormProdutor: React.FC = () => {
  const {
    canUpdate,
    formNfe,
    especies,
    numChaveAcesso,
    oldCodPessoa,
    setOldCodPessoa,
    xmlImportado,
  } = useEntradaNFE();

  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState,
  } = formNfe;

  const watchCodPessoa = watch('cod_pessoa');

  const produtoExportador = useCallback(
    async (cod_pessoa: number) => {
      try {
        const reg = await api.get(`/fornecedor/${cod_pessoa}`);
        const { data, success } = reg.data;
        if (success) {
          const { flg_produtor_rural } = data;
          if (flg_produtor_rural) {
            setValue('num_cpf_cnpj_produtor', data.num_cpf_cnpj);
            setValue('num_rg_ie_produtor', data.num_ie);
            if (numChaveAcesso) {
              setValue('num_chave_produtor', numChaveAcesso);
              setValue('des_especie_produtor', {
                des_doc_fiscal: 'NOTA FISCAL ELETRÔNICA',
                des_especie: 'NFE',
                label: 'NFE',
                value: 33,
                cod_doc_fiscal: '55',
                num_modelo_fiscal: '',
              });
            }
          }
        }
      } catch (error) {
        toast.error(`${error}`);
      }
    },
    [numChaveAcesso, setValue],
  );

  useEffect(() => {
    if (
      watchCodPessoa?.value !== undefined &&
      watchCodPessoa?.value !== oldCodPessoa
    ) {
      produtoExportador(watchCodPessoa.value);
      setOldCodPessoa(watchCodPessoa.value);
    }
  }, [
    watchCodPessoa,
    numChaveAcesso,
    oldCodPessoa,
    produtoExportador,
    setOldCodPessoa,
  ]);

  return (
    <>
      <Separator labelText="Produtor" sidePadding="0 0" />

      <Row>
        <Col md={2} sm={12}>
          <InputMoney
            label="INSS"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_funrural"
            register={register}
            isError={false}
            showIcon
            disabled={!canUpdate}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputNumber2
            label="Nº NF"
            max={9999999999}
            maxLength={10}
            placeholder="0"
            name="num_nf_produtor"
            register={register}
            disabled={!canUpdate}
            isError={!!formState.errors.num_nf_produtor}
            control={control}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputText
            label="Série"
            placeholder="Informe a série..."
            name="num_serie_produtor"
            register={register}
            isError={!!formState.errors.num_serie_produtor}
            control={control}
            disabled={!canUpdate}
            onKeyDown={(e) => {
              if (e.key === ' ' || e.code === 'Space') {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
              const pastedText = e.clipboardData.getData('text');
              const modifiedText = pastedText.replace(/\s+/g, '');
              const inputElement = e.target as HTMLInputElement;
              inputElement.value = modifiedText;
            }}
            maxLength={8}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputDate
            label="Emissão"
            register={register}
            name="dta_emissao_produtor"
            isError={!!formState.errors.dta_emissao_produtor}
            control={control}
            disabled={!canUpdate}
          />
        </Col>

        <Col md={2} sm={12}>
          <InputSelect
            label="Espécie"
            maxLength={50}
            placeholder="Selecione..."
            name="des_especie_produtor"
            register={register}
            autoComplete="off"
            isError={!!formState.errors.des_especie_produtor}
            control={control}
            options={especies}
            disabled={!canUpdate}
            changeSelected={(selected) => {
              clearErrors('des_especie_produtor');
              setValue('des_especie_produtor', selected);
            }}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputUf
            maxLength={2}
            setValue={setValue}
            placeholder="Selecione..."
            name="des_uf_produtor"
            register={register}
            isError={!!formState.errors.des_uf_produtor}
            control={control}
            disabled={!canUpdate}
            changeSelected={(selected) => {
              clearErrors('des_uf_produtor');
              setValue('des_uf_produtor', selected);
            }}
          />
        </Col>

        <Col md={3} sm={12}>
          <InputText
            label="CNPJ/CPF"
            maxLength={50}
            placeholder=""
            name="num_cpf_cnpj_produtor"
            register={register}
            isError={!!formState.errors.num_cpf_cnpj_produtor}
            disabled={!canUpdate}
          />
        </Col>
        <Col md={3} sm={12}>
          <InputText
            label="IE"
            maxLength={50}
            placeholder=""
            name="num_rg_ie_produtor"
            register={register}
            disabled={!canUpdate}
            isError={!!formState.errors.num_rg_ie_produtor}
          />
        </Col>
        <Col md={6} sm={12}>
          <InputText
            label="Chave NF-e"
            maxLength={50}
            placeholder=""
            name="num_chave_produtor"
            register={register}
            disabled={!canUpdate || xmlImportado}
            isError={!!formState.errors.num_chave_produtor}
            toLowerCase={false}
          />
        </Col>
      </Row>
    </>
  );
};
