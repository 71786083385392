import moment from 'moment';
import { FormData, Critica, Fornecedor } from '../protocols';
import { createCritica } from './CreateCritica';

export function fornecedorValidation(
  fornecedor: Fornecedor,
  formData: FormData,
): Critica[] {
  const criticas: Critica[] = [];

  const validaSerieProdutor = () => {
    const serieProdutor = formData.num_serie_produtor || '';
    if (serieProdutor === '') {
      const critica = createCritica(
        6,
        'Série da NF de Produtor não Preenchido.',
        `
        Fornecedor marcado como Produtor Rural. \n
        Informe a Série da NF na aba Produtor
        `,
      );
      criticas.push(critica);
    }
  };

  const validaUfProdutor = () => {
    const UfProdutor = formData.des_uf_produtor || '';
    if (UfProdutor === '') {
      const critica = createCritica(
        6,
        'UF do Produtor não Preenchido',
        `
        Fornecedor marcado como Produtor Rural. \n
        Informe a UF da NF na aba Produtor.
        `,
      );
      criticas.push(critica);
    }
  };

  const validaDataEmissaoProdutor = () => {
    let dataEmissaoProdutor: any = formData.dta_emissao_produtor || '';
    if (dataEmissaoProdutor === '') {
      const critica = createCritica(
        6,
        'Data de Emissão da NF do Produtor não Preenchido.',
        `
        Fornecedor marcado como Produtor Rural. \n
        Informe a Data de Emissão da NF Produtor
        `,
      );
      criticas.push(critica);
    } else {
      const dtaEmissao = moment(formData.dta_emissao);
      dataEmissaoProdutor = moment(dataEmissaoProdutor);

      if (dataEmissaoProdutor > dtaEmissao) {
        const critica = createCritica(
          6,
          'Data de Emissão da NF Produtor.',
          'A Data de Emissão da NF de Produtor deve ser menor ou igual a Data de Emissão da NF.',
        );
        criticas.push(critica);
      }
    }
  };

  if (fornecedor.flg_produtor_rural) {
    validaSerieProdutor();
    validaUfProdutor();
    validaDataEmissaoProdutor();
  }

  return criticas;
}
