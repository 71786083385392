import React from 'react';
import { FormExportador } from './FormExportador';
import { FormObservacao } from './FormObservacao';
import { FormProdutor } from './FormProdutor';

export const Produtor: React.FC = () => {
  return (
    <>
      <FormProdutor />
      <FormExportador />
      <FormObservacao />
    </>
  );
};
