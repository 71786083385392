import moment from 'moment';
import { FormData, Critica } from '../protocols';
import { createCritica } from './CreateCritica';

export function guiaValidation(formData: FormData): Critica[] {
  const criticas: Critica[] = [];

  const valTotGuiaST = formData.val_total_guia_st_forn;
  const valTotGuiaSTSefaz = formData.val_total_guia_st_sefaz;

  /**
   * Verifica se o valor da guia st foi preenchido para que faça a validação na data de vencimento
   * e na data de recolhimento
   */

  if (valTotGuiaST > 0) {
    if (formData.dta_vencto_guia_st_forn === '') {
      const critica = createCritica(
        3,
        'Data de vencimento da guia ST deve ser informado.',
        'Informe a data de vencimento da guia ST.',
      );
      criticas.push(critica);
    } else {
      const dtaEmissao = moment(formData.dta_emissao);
      const dtaVencimentoGuia = moment(formData.dta_vencto_guia_st_forn);
      const dtaRecGuia = moment(formData.dta_rec_guia_st_forn);

      if (dtaVencimentoGuia < dtaEmissao) {
        const critica = createCritica(
          3,
          'Data de vencimento da guia ST.',
          'A data de vencimento da guia ST deve ser maior ou igual a data de emissão da nf.',
        );
        criticas.push(critica);
      }

      if (dtaRecGuia < dtaEmissao) {
        const critica = createCritica(
          3,
          'Data de recolhimento da guia ST.',
          'A data de recolhimento da guia ST deve ser maior ou igual a data de emissão da nf',
        );
        criticas.push(critica);
      }
    }
  }

  /**
   * Verifica se o valor da guia st sefaz foi preenchido para que faça a validação na data de vencimento
   *
   */

  if (valTotGuiaSTSefaz > 0) {
    if (formData.dta_vencto_guia_sefaz === '') {
      const critica = createCritica(
        3,
        'Data de vencimento da guia Sefaz deve ser informado.',
        'Informe a data de encimento da Guia Sefaz.',
      );
      criticas.push(critica);
    } else {
      const dtaEmissao = moment(formData.dta_emissao);
      const dtaVencGuiaSefaz = moment(formData.dta_vencto_guia_sefaz);

      if (dtaVencGuiaSefaz < dtaEmissao) {
        const critica = createCritica(
          3,
          'Data de Vencimento da Guia SEFAZ ',
          'A Data de Vencimento da Guia Sefaz deve ser maior ou igual a Data de Emissão da NF.',
        );
        criticas.push(critica);
      }
    }
  }

  /**
   * Verifica se o valor total da guia informado, difere do valor calculado
   */

  const valTotGuiaInfo = valTotGuiaST;
  let totGuiaItem = 0;
  let totGuiaItemDif = 0;

  formData.itens.map((item) => {
    totGuiaItem += item.val_bc_st_guia;
    totGuiaItemDif += item.val_icms_st_guia_dif;
    return item;
  });

  if (valTotGuiaST.toFixed(2) !== (totGuiaItem + totGuiaItemDif).toFixed(2)) {
    const critica = createCritica(
      3,
      'Total Guia ST informado difere do calculado.',
      `Total Guia ST Informado: ${valTotGuiaST.toFixed(2)} 
      \n Total Guia ST Calculado: ${(totGuiaItem + totGuiaItemDif).toFixed(2)}`,
    );
    criticas.push(critica);
  }

  return criticas;
}
