import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import { InputSelect, InputTextArea } from '~/components/NovosInputs';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';
import Separator from '~/components/Separator';
import { useEntradaNFE } from '~/pages/EntradaNFE/EntradaNFEContext';
import {
  InfoComplementarNFE,
  ResponseInfoComplementar,
  SeletorInfo,
} from '~/pages/EntradaNFE/protocols/InformacaoComplementar';
import api from '~/services/api';

export const FormObservacao: React.FC = () => {
  const [infos, setInfos] = useState<SeletorInfo[]>([]);
  const [allInfos, setAllInfos] = useState<InfoComplementarNFE[]>([]);

  const { formNfe, canUpdate } = useEntradaNFE();

  const { register, control, formState, setValue } = formNfe;

  const getInfoComplementar = useCallback(async () => {
    const { data } = await api.get<ResponseInfoComplementar>(
      '/entrada-nfe/informacao/complementar',
    );

    if (data.success) {
      let options: SeletorInfo[] = data.data.map((opt) => ({
        label: `${opt.cod_info_complementar} - ${opt.des_titulo}`,
        value: opt.cod_info_complementar,
      }));

      options = options.sort((a, b) => Number(a.value) - Number(b.value));

      const newOptions: SeletorInfo[] = [
        { label: 'Nenhuma', value: null },
        ...options,
      ];

      setInfos(newOptions);
      setAllInfos(data.data);
    } else {
      setInfos([{ label: 'Nenhuma', value: null }]);
      setAllInfos([]);
    }
  }, []);

  useEffect(() => {
    getInfoComplementar();
  }, []);

  return (
    <>
      <Separator labelText="Observação da Nota Fiscal" sidePadding="0 0" />
      <Row>
        <Col md={4} sm={12}>
          <InputSelect
            label="Informação Complementar"
            placeholder="Nenhuma"
            name="cod_info_complementar"
            register={register}
            isError={false}
            disabled={!canUpdate}
            control={control}
            options={infos as SelectType[]}
            changeSelected={(selected) => {
              setValue('cod_info_complementar', selected);

              if (!selected.value) setValue('des_obs_fiscal', '');
              else {
                const info = allInfos.find(
                  (i) =>
                    Number(i.cod_info_complementar) === Number(selected.value),
                );

                if (info) setValue('des_obs_fiscal', info.des_informacao);
                else setValue('des_obs_fiscal', '');
              }
            }}
          />
        </Col>
        <Col md={12} sm={12}>
          <InputTextArea
            maxLength={500}
            label="Observação"
            name="des_obs_fiscal"
            register={register}
            control={control}
            isError={!!formState.errors.des_obs_fiscal}
            disabled={!canUpdate}
          />
        </Col>
      </Row>
    </>
  );
};
